import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpandablePanel = makeShortcode("ExpandablePanel");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "point-of-sale-registry"
    }}>{`Point-of-Sale Registry`}</h1>
    <p>{`The Point-of-Sales Registry keeps track of all points of sale, both manned (e.g. a vendor on board a train) and unmanned (e.g a ticket vending machine), using our cash register system. The registry will be populated by the operators through Entur Partner. It is not possible to do a sale from a point of sale not added to this registry.`}</p>
    <h2 {...{
      "id": "authentication"
    }}>{`Authentication`}</h2>
    <p>{`The POS-registry requires for every request a bearer token in the Authorization header either as a internal (Entur employee) user or a partner (e.g NSB employee). You may obtain the token using the Entur Auth0 bearer token provider,  see `}<a parentName="p" {...{
        "href": "https://live-entur.devportal.apigee.io/content/authentication"
      }}>{`https://live-entur.devportal.apigee.io/content/authentication`}</a></p>
    <h2 {...{
      "id": "short-description-of-pos-registry-api"
    }}>{`Short description of POS-registry API`}</h2>
    <h3 {...{
      "id": "get-get-a-location"
    }}>{`GET Get a location`}</h3>
    <p>{`Get the location with the given Id`}</p>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
      <pre>
        {`{
    "id": 10001520,
    "name": "Voss",
    "latitude": "60.6585",
    "longitude": "6.4653",
    "nsrCode": "NSR:StopPlace:440"
}
`}
      </pre>
    </ExpandablePanel>
    <h3 {...{
      "id": "get-get-all-locations"
    }}>{`GET Get all locations`}</h3>
    <p>{`Get all locations in the database`}</p>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
      <pre>
        {`[
    {
        "id": 10000000,
        "name": "moss",
        "latitude": null,
        "longitude": null,
        "nsrCode": null
    },
    {
        "id": 10000240,
        "name": "test-location-1",
        "latitude": null,
        "longitude": null,
        "nsrCode": null
    },
    {
        "id": 10001520,
        "name": "Voss",
        "latitude": "60.6585",
        "longitude": "6.4653",
        "nsrCode": "NSR:StopPlace:440"
    },
    {
        "id": 10001580,
        "name": "Holmlia stasjon",
        "latitude": "59.5005",
        "longitude": "10.4749",
        "nsrCode": "NSR:StopPlace:59636"
    },
    {
        "id": 10001700,
        "name": "Old Trafford",
        "latitude": null,
        "longitude": null,
        "nsrCode": "337"
    },
    {
        "id": 11000034,
        "name": "Trondheim",
        "latitude": null,
        "longitude": null,
        "nsrCode": "NSR:StopPlace:659"
    },
    {
        "id": 11000035,
        "name": "Lillehammer",
        "latitude": null,
        "longitude": null,
        "nsrCode": "NSR:StopPlace:420"
    },
    {
        "id": 11000036,
        "name": "Oslo Gardemoen",
        "latitude": null,
        "longitude": null,
        "nsrCode": "NSR:StopPlace:269"
    },
    {
        "id": 11000037,
        "name": "Bergen",
        "latitude": null,
        "longitude": null,
        "nsrCode": "NSR:StopPlace:548"
    },
    {
        "id": 11000038,
        "name": "Stavanger",
        "latitude": null,
        "longitude": null,
        "nsrCode": "NSR:StopPlace:596"
    },
    {
        "id": 10000460,
        "name": "Oslo S",
        "latitude": null,
        "longitude": null,
        "nsrCode": "NSR:StopPlace:59872"
    }
]
`}
      </pre>
    </ExpandablePanel>
    <h3 {...{
      "id": "post-create-new-location"
    }}>{`POST Create new location`}</h3>
    <p>{`Creates a new Location and returns the created object.`}</p>
    <ExpandablePanel title="Example request" mdxType="ExpandablePanel">
      <pre>
        {`{
    "name": "Voss",
    "geoLocation": {
        "latitude": "58.03458",
        "longitude": "10.345897"
    }
}
`}
      </pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
      <pre>
        {`{
    "id": 10001520,
    "name": "Voss",
    "latitude": "60.6585",
    "longitude": "6.4653",
    "nsrCode": "NSR:StopPlace:440"
}
`}
      </pre>
    </ExpandablePanel>
    <h3 {...{
      "id": "get-get-a-pos"
    }}>{`GET Get a PoS`}</h3>
    <p>{`Get a PoS with the given ID`}</p>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
      <pre>
        {`{
    "id": 10000820,
    "privateCode": "157ac880-7072-11e8-adc0-fa7ae01bbebc",
    "name": "Ess-Unified",
    "location": {
        "id": 10000460,
        "name": "Oslo S",
        "latitude": null,
        "longitude": null,
        "nsrCode": "NSR:StopPlace:59872"
    },
    "distributionChannelId": "ENT:DistributionChannel:sales_and_service",
    "organisationId": 1,
    "active": true
}
`}
      </pre>
    </ExpandablePanel>
    <h3 {...{
      "id": "post-create-pos"
    }}>{`POST Create PoS`}</h3>
    <p>{`Create a new Point of sale with a PointOfSaleRequest as input and returns the created objects.`}</p>
    <ExpandablePanel title="Example request" mdxType="ExpandablePanel">
      <pre>
        {`{
  "privateCode": 567765344,
  "name": "luke1-Oslos",
  "locationId": 36776134434,
  "distributionChannelId": "ENT:DistributionChannel:app",
  "organisationId": "1",
  "active": true
}
`}
      </pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
      <pre>
        {`{
  "id": 567765344,
  "privateCode": 567765344,
  "name": "luke1-Oslos",
  "location": {
    "id": 567765344,
    "name": "Oslos",
    "latitude": 58.023585,
    "longitude": 10.023585,
    "nsrCode": 1432
  },
  "distributionChannelId": "ENT:DistributionChannel:app",
  "organisationId": 0,
  "active": true
}
`}
      </pre>
    </ExpandablePanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      